import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100vw;
	overflow-x: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;		
`;

export const HeaderContainer = styled.div`
	width: calc(100% - 40px);
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const PageHeading = styled.h1`
	margin: 0px;
	padding: 0px;
`;

export const VideoPlayer = styled.video.attrs({autoPlay: true})`
	width: 800px;
	height: 450px;
	border: 2px solid black;
`;

export const RecordingIndicator = styled.h2`
	color: ${({theme}) => theme.colors.black};
`;

export const BottomButtonBar = styled.div`
	display: flex;
	align-items: center;
`;

export const LetterListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;	
`;

export const LetterList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Letter = styled.h1`
	margin: 10px;
	padding: 20px;
	box-shadow: 4px 4px 16px rgba(0,0,0,0.5);
	cursor: pointer;
`;