import styled from 'styled-components';

export const PlainInput = styled.input.attrs(({type}) => ({type: type || 'text'}))`
	border: none;
	outline: none;
	background-color: ${({background}) => background || "#fff"};
	border: 1px solid #ccc;
	padding: 10px 10px;
	margin: 5px 0px;
	border-radius: 5px;
	width: calc(100% - 40px);
	text-align:center;
	font-size:20px;
`;

