import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {
	RootContainer,
	Title,
	Subtitle,
	ParticipantWrapper,
	ParticipantContainer,
	ParticipantEmail,
	ParticipantName,
	InstructionsContainer,
	InstructionList,
	InstructionListItem,	
} from './Instructions.styles';
import {
	VerticalSpace,
	PrimaryButton,
} from 'components';
import {useNavigate} from 'react-router-dom';

const Instructions = ({screen}) => {
	const navigate = useNavigate()
	const {profile, accountId, action} = useSelector(state => state.user, shallowEqual)

	if(screen === 'assesment') {
		return (
			<RootContainer>
				<Title>Instructions</Title>
				<Subtitle>Assesment</Subtitle>
				<VerticalSpace count={2} />
				<InstructionsContainer>
					Assesment Instructions Here
				</InstructionsContainer>
				<VerticalSpace count={2} />
				<PrimaryButton onClick={() => navigate('/assesment')} >Proceed</PrimaryButton>
				<VerticalSpace count={2} />
			</RootContainer>
		)
	}
	return (
		<RootContainer>
			<ParticipantWrapper>
				<ParticipantContainer>
					<ParticipantName>
						Welcome, {profile.name}
					</ParticipantName>
				</ParticipantContainer>
			</ParticipantWrapper>
			<VerticalSpace count={2} />
			<Title>Instructions</Title>
			<Subtitle>Practice</Subtitle>
			<VerticalSpace count={2} />
			<InstructionsContainer>
				<InstructionList>
					<InstructionListItem>
						Choose one of the letters to get started with your practice.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						Once a letter is selected, you will be shown a video to help you practice the letter.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						The video will pause when its your turn to tell the letter out loud.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						Once you speak out the letter the video will resume playing.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						Anytime during the video you can press the "Next letter" button to go to the next video.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						Anytime during the video you can press the "Save and Exit" button to end the practice session.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						Please don't refresh the page or go back! Your session will be lost.
					</InstructionListItem>
					<VerticalSpace count={1} />
					<InstructionListItem>
						That's all you need to get started. <b>Good luck!</b>
					</InstructionListItem>
				</InstructionList>
			</InstructionsContainer>
			<VerticalSpace count={4} />
			<PrimaryButton onClick={() => navigate('/practice')} >Proceed →</PrimaryButton>
			<VerticalSpace count={2} />
		</RootContainer>
	)
}

export default Instructions;