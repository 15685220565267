import axios from 'axios'

export const getUser = (id) => {
	return axios.get(`/getAccount/${id}`)
}

export const login = (data) => {
	return axios.post('/login', {...data})
}

export const signup = (data) => {
	return axios.post('/signup', {...data})
}

export const saveAnswer = (data) => {
	return axios.post(`/saveAnswer`, {...data})
}

export const getPresentation = (data) => {
	return axios.get(`https://l7rgl808hg.execute-api.ap-south-1.amazonaws.com/v1/qa-pptx?id=${data.id}&collections=lettersanswers`)
}

export const getAllAnswers = (id) => {
	return axios.get(`/getAllAnswers/${id}`)
}

export const saveUser = (data) => {
	return axios.post(`/saveAccount`, {accountId: data.id, name: data.name, email: data.email})
} 