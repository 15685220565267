import styled from 'styled-components';

export const RootContainer = styled.div`
	background: #E0EAFC;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h1`
	color: ${({theme}) => theme.colors.black};
	text-align: center;
`;

export const Subtitle = styled.h2`
	color: ${({theme}) => theme.colors.black};
	text-align: center;
`;

export const ParticipantWrapper = styled.div`
	display: flex;
	align-items: center;
	// background-color: ${({theme}) => theme.colors.lightBlue};
	margin: 15px;
	width: calc(100% - 60px);
	padding: 15px;
`;

export const ParticipantContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const ParticipantEmail = styled.div`
	color: ${({theme}) => theme.colors.black};
	font-size: 20px;
`;

export const ParticipantName = styled(ParticipantEmail)`
	font-weight: bold;
	line-height: 22px;
`;

export const InstructionsContainer = styled.div`
	width: 50vw;
	// flex: 0.1; //add a padding to instructions
	padding: 10px 30px;
	background: ${({theme}) => theme.colors.white};
	box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	overflow-y: scroll;
`;

export const InstructionList = styled.ul`
	display: block;
`;

export const InstructionListItem = styled.li`
	font-size: 20px;
`;