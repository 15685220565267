import {useState, useEffect} from 'react';
import {
	RootContainer,
	BodyContainer,
	WelcomeText,
	MainText,
	ArrowImage,
	FormContainer,
	FormHeading,
	LogoImage,
	NoteText,
} from './Auth.styles';
import {
	PlainInput,
	VerticalSpace,
	PrimaryButton,
	UnderlinedButton
} from 'components';
import {useNavigate} from 'react-router-dom';
import Arrow from 'assets/images/down-arrow-png-image.png';
import Logo from 'assets/images/brainsprays_logo400.png';
import {useTransition} from 'react-spring';
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from 'modules/user';

const gradients = [
    // ['#2193b0', '#6dd5ed'],
    // ['#009FFF', '#ec2F4B'],
    ['#000046', '#75e1ff'],
];

const Auth = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user)
	const [showNameInput, setShowNameInput] = useState(false)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const onSubmitPress = () => {
		if(showNameInput) {
			dispatch(userActions.signupAsyncActions.request({name, email, password}))
			return null;
		}

		dispatch(userActions.loginAsyncActions.request({email, password}))
	}

	useEffect(() => {
		if(user.accountId)
			navigate('/dashboard')
	}, [user])

	return (
		<RootContainer gradients={ gradients } property="background" duration={3000} >
			<LogoImage src={ Logo } />		
			<BodyContainer>
				<WelcomeText>Welcome to Brainsprays Letters!</WelcomeText>
				<VerticalSpace count={2} />
				<MainText>Practice Letters</MainText>
				<ArrowImage src={Arrow} />
				<FormContainer>
					<FormHeading>{showNameInput?"Create Your Account":"Welcome Back, Login!"}</FormHeading>
					<VerticalSpace count={2} />
					{
						showNameInput
						?
						<>
							<PlainInput placeholder="Name" onChange={evt => setName(evt.target.value)} value={name} />
							<VerticalSpace count={0.5} />
						</>
						:
						null
					}
					<PlainInput placeholder="Email" onChange={evt => setEmail(evt.target.value)} value={email} />
					<VerticalSpace count={0.5} />
					<PlainInput placeholder="Password" type="password" onChange={evt => setPassword(evt.target.value)} value={password} />
					<VerticalSpace count={0.5} />
					{
						user.isLoading
						?
						<PrimaryButton background="lightGrey" >Loading...</PrimaryButton>
						:
						<PrimaryButton onClick={onSubmitPress} >{showNameInput?"Sign up →":"Sign in →"}</PrimaryButton>
					}
					<VerticalSpace count={2} />
					<UnderlinedButton onClick={() => setShowNameInput(p => !p)} >{showNameInput?"Already have an account? Sign in here":"New here? Crete a free account now"}</UnderlinedButton>
				</FormContainer>
				<NoteText>By clicking start, you agree to <a href="https://prepair.ai/privacy" target="_blank">Privacy Policy</a> and the <a href="https://prepair.ai/terms-of-use" target="_blank">Terms and Conditions</a>.</NoteText>
			</BodyContainer>
		</RootContainer>
		)
}

export default Auth;