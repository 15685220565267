import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${({theme}) => theme.colors.white};	
`;

export const HeaderContainer = styled.div`
	// display: flex;
	align-items: center;
	// justify-content: space-between;
`;

export const PageTitle = styled.h1`
	font-size: 2.5rem;
	margin: 10px 20px;
	padding: 0;
	color: ${({theme}) => theme.colors.darkGrey};
	text-align:center;
`

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 10px 20px;
`;

export const BodyContainer = styled.div`
	width: calc(100% - 40px);
	padding: 20px;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: wrap;
`;

export const VideoTileContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 300px;
	height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 10px;
	margin: 10px 10px;
	border-radius: 10px;
	box-shadow: 4px 4px 16px rgba(0,0,0,0.5);
`;

export const VideoTileTitle = styled.p`
	font-weight: bold;
`;

export const Chapter = styled.div`
	margin: 5px;
	padding: 5px;
	background-color: #fff;


	&:nth-child(even) {
		background-color: #eee;
	}
`;