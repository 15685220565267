import {useState, useEffect} from 'react';
import {
	RootContainer,
	LogoImage,
	BodyContainer,
	MainText,
	TaglineText,
	GifImage,
} from './Thanks.styles';
import { Fireworks } from 'fireworks-js/dist/react'
import Logo from 'assets/images/brainsprays_logo400.png';
// import GIF from 'assets/images/leonardo-dicaprio.gif';
import {PrimaryButton, VerticalSpace} from 'components';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const options = {
    speed: 3,
    rocketsPoint: 50,
    particles: 90
  }

  const style = {
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    background: '#FFF',
    zIndex: -1
  }

const Thanks = () => {
	const navigate = useNavigate();

	useEffect(() => {
		toast.dismiss()
	}, [])

	return (
		<RootContainer>
			<Fireworks options={options} style={style} />
			<LogoImage src={Logo} />
			<BodyContainer>
				<MainText>Awesome! You practiced some letters today 👏</MainText>
				<TaglineText>Go back to the dashboard to view your recording and PowerPoint (PPT) slide.</TaglineText>
				<VerticalSpace count={4} />
				{/*<GifImage src={GIF} />*/}
				<GifImage loading="lazy" src="https://media1.giphy.com/media/tCV2LrPPYfqCY/giphy.gif" alt="success" />
				<VerticalSpace count={3} />
				<PrimaryButton onClick={() => navigate('/dashboard')} >Go to dashboard →</PrimaryButton>
			</BodyContainer>
		</RootContainer>
	)
}

export default Thanks;