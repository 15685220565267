import {useState, useEffect} from 'react';
import {
	RootContainer,
	HeaderContainer,
	PageTitle,
	HeaderButtonContainer,
	BodyContainer,
	VideoTileContainer,
	VideoTileTitle,
	Chapter,
} from './Dashboard.styles';
import {BsPlayCircleFill} from 'react-icons/bs';
import {PrimaryButton, VerticalSpace, HorizontalSpace} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import format from 'format-duration';

const Dashboard = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)

	useEffect(() => {
		dispatch(userActions.getDashboardDataAsyncActions.request(user?.accountId))
	}, [])

	return (
		<RootContainer>
			<HeaderContainer>
				<PageTitle>Dashboard</PageTitle>
				<HeaderButtonContainer>
					<PrimaryButton onClick={() => dispatch(userActions.logoutAction.action())} >Sign out</PrimaryButton>
					<HorizontalSpace count={140} />
					<PrimaryButton onClick={() => navigate('/practice-instructions')} >Practice now →</PrimaryButton>
				</HeaderButtonContainer>
			</HeaderContainer>
			<VerticalSpace count={3} />
			<BodyContainer>
				{
					user.allAnswers.length
					?
					user.allAnswers.map(answer => {
						return (
							<VideoTileContainer>
								<VideoTileTitle>{moment(answer.createdOn).format("DD MMM YYYY")} - <a href={answer.objectURL || '/'} >PPT link</a></VideoTileTitle>
								<audio src={answer.audioUrl} controls style={{minHeight: '54px'}} />
								{
									answer.answers.map(chapter => {
										return (
											<Chapter>{chapter.question.slice(0, 200)} - {format(chapter.timestamp*1000)}</Chapter>
										)
									})
								}
							</VideoTileContainer>
						)
					})
					:
					<VideoTileTitle>No recording yet! Click on <strong>Practice now</strong> button to get started</VideoTileTitle>
				}
			</BodyContainer>
		</RootContainer>
	)
}

export default Dashboard;

// 8299045765