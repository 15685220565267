import {useState, useEffect, useRef} from 'react';
import {
	RootContainer,
	HeaderContainer,
	PageHeading,
	VideoPlayer,
	RecordingIndicator,
	BottomButtonBar,
	LetterListContainer,
	LetterList,
	Letter,
} from './Practice.styles';
import {VerticalSpace, HorizontalSpace, PrimaryButton, Modal} from 'components';
import {b, c, e, j} from 'assets/videos';
import useSpeechToText from 'react-hook-speech-to-text';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {userActions} from 'modules/user';
import useRecorder from 'utilities/useRecorder';
import Timer from 'tiny-timer';

const VideoArray = [
	{
		letter: 'b',
		video: b,
		pauseAt: 19.2
	},
	{
		letter: 'c',
		video: c,
		pauseAt: 19.0,
	},
	{
		letter: 'e',
		video: e,
		pauseAt: 19.1
	},
	{
		letter: 'j',
		video: j,
		pauseAt: 19.2
	},
]

const Practice = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {
	    error,
	    isRecording,
	    interimResult,
	    results,
	    setResults,
	    startSpeechToText,
	    stopSpeechToText,
	  } = useSpeechToText({
	    // continuous: false,
	    continuous: true,
	    crossBrowser: true,
	    googleApiKey: "AIzaSyBgItgnqpBRRFpdZ_tQRQW3D9EvIl0emUE",
	    googleCloudRecognitionConfig: {
	      languageCode: 'en-US'
	    },
	    useLegacyResults: false,
	    // timeout: 3000
	});
	const timer = new Timer({interval: 1000, stopwatch: true})
	const [audioURL, isVoiceRecording, startRecording, stopRecording] = useRecorder({audio: true});
	const playerRef = useRef(null);
	const [currentVideo, setCurrentVideo] = useState(-1)
	const [currentAttempt, setCurrentAttempt] = useState(1)
	const [responseRecorded, setResponseRecorded] = useState(false)
	const [recordingStarted, setRecordingStarted] = useState(false)
	const [time, setTime] = useState(0)

	const onCurrentTimeUpdate = (currentTime) => {
		if(VideoArray[currentVideo].pauseAt <= currentTime && !responseRecorded && !recordingStarted) {
			setRecordingStarted(true)
			playerRef.current.pause()
			startSpeechToText()
		}
	}
	
	const processResult = (response) => {
		const object = {
			questionNumber: currentVideo+1,
			question: `${currentAttempt} - Letter ${VideoArray[currentVideo]?.letter.toUpperCase()}`,
			answer: `${response === VideoArray[currentVideo]?.letter?'':'**Wrong Answer** '}${response}`,
			timestamp: time >= 10?time-10:0,
		}

		dispatch(userActions.updateAnswerAction.action(object))
		setResponseRecorded(true)
		playerRef.current.play()
		setRecordingStarted(false)
		stopSpeechToText()
		setResults([])
	}

	const onNextButtonClick = () => {
		if(VideoArray[currentVideo+1]) {
			setCurrentVideo(p => p+1)
		}
		else {
			setCurrentVideo(0)
			setCurrentAttempt(p => p+1)
		}
	}

	const onSave = () => {
		// console.log("Stop Recording", isVoiceRecording)
		stopRecording()
	}

	useEffect(() => {
		// console.log("RESULTS", results)
		if(results?.length) {
			processResult(results[0].transcript.toLowerCase())
		}
	}, [results])

	useEffect(() => {
		if(playerRef?.current) {
			setResponseRecorded(false)
			setRecordingStarted(false)
			stopSpeechToText()
			playerRef.current.src = VideoArray[currentVideo].video;
			playerRef.current.load()
			playerRef.current.play()
		}
	}, [currentVideo])

	useEffect(() => {
		startRecording()
		timer.start(1000*60*60)
		timer.on('tick', (milliseconds) => {
			setTime(Math.floor(milliseconds/1000))
		})
	}, [])

	return (
		<RootContainer>
			<HeaderContainer>
				<PageHeading>Practice</PageHeading>
			</HeaderContainer>
			<VerticalSpace count={1} />
			{
				currentVideo !== -1
				?
				<VideoPlayer controls={0} ref={playerRef} onTimeUpdate={evt => onCurrentTimeUpdate(evt.target.currentTime)} />
				:
				null
			}
			<VerticalSpace count={1} />
			<RecordingIndicator>{recordingStarted?"Please speak out the letter now 🗣️":""}</RecordingIndicator>
			<VerticalSpace count={1} />
			<BottomButtonBar>
				<PrimaryButton onClick={onSave} >Save and Exit</PrimaryButton>
				<HorizontalSpace count={10} />
				<PrimaryButton onClick={onNextButtonClick} >Next letter →</PrimaryButton>			
			</BottomButtonBar>
			<VerticalSpace count={1} />
			{
				currentVideo === -1
				?
				<Modal>
					<LetterListContainer>
						<h2>Select a letter to start 👇</h2>
						<LetterList>
							{
								VideoArray.map((video, index) => <Letter onClick={() => setCurrentVideo(index)} >{video.letter.toUpperCase()}</Letter>)
							}
						</LetterList>
					</LetterListContainer>
				</Modal>
				:
				null
			}
		</RootContainer>
	)
}

export default Practice;