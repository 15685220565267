import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes,
  Navigate,
} from "react-router-dom";
import {
	Auth,
	Instructions,
	Dashboard,
	Practice,
	Thanks,
} from 'screens';
import {useSelector} from 'react-redux';

const ProtectedRoute = ({children}) => {
	const {accountId} = useSelector(state => state.user)

	if(accountId)
		return children

	return <Navigate to="/" replace />
}

const RootRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Auth />} />
				<Route path='/practice-instructions' element={<ProtectedRoute><Instructions screen="practice" /></ProtectedRoute>} />
				<Route path='/assesment-instructions' element={<ProtectedRoute><Instructions screen="assesment" /></ProtectedRoute>} />
				<Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
				<Route path='/practice' element={<ProtectedRoute><Practice /></ProtectedRoute>} />
				<Route path='/thanks' element={<ProtectedRoute><Thanks /></ProtectedRoute>} />
			</Routes>
		</Router>
	)
}

export default RootRouter;

/* 
<Route path='/instructions' element={<Instructions />} />
<Route path='/interview' element={<Interview />} />
<Route path='/thanks/' element={<Thanks />} />
<Route path='/dashboard' element={<Dashboard />} />
*/