import styled from 'styled-components';
import {Gradient} from 'react-gradient';
import {animated} from 'react-spring';

export const AnimatableContainer = styled(animated.div)``;

export const RootContainer = styled(Gradient)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

export const LogoImage = styled.img`
	height: 100px;
	width: auto;
`;


export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const WelcomeText = styled.h1`
	font-size: 2.5rem;
	line-height: 1.5rem;
	color: #F5F5F5;
`;

export const MainText = styled.div`
	font-size: 2rem;
	margin: 0px;
	color: #F5F5F5;
`;

export const ArrowImage = styled.img`
	height: 100px;
	width: 60px;
	opacity: 0.7;
`;

export const FormContainer = styled.div`
	width: 30vw;
	background-color: ${({theme}) => theme.colors.white};
	padding: 10px 20px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const FormHeading = styled.h1`
	text-align: center;
`;

export const NoteText = styled.p`
	color: ${({danger}) => danger?"#ffffff":"#ffffff"};
	{ a, a:hover, a:active, a:visited { color: white; }
`;



